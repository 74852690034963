// import logo from './logo.png';
import React/*, { lazy , Suspense  }*/ from 'react';
//import { Route, Switch } from 'react-router-dom'
/* import config from './constants/config' */
import './App.css';
import {
  ErrorOutlineOutlined,
} from "@mui/icons-material";
import {
  Box,

} from "@mui/material";
import { Toaster } from 'react-hot-toast';
import MainPage from './components/MainPage';
import localforage from "localforage";

const instaMonet = (props) => (
  <div id="InstaMonet" style={{ width: "100%" }} className="App">
    <MainPage {...props} />
    <Toaster />

  </div>
)
function App() {
  localforage.config({
    driver: localforage.INDEXEDDB,
    name: 'instamonet',
    storeName: "instamonet_protocol",
  });
  localforage.setDriver(localforage.INDEXEDDB);
  return instaMonet()
}
export default App;
