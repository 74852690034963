import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import { Typography } from '@mui/material';
import logo from '../assets/images/logo.png';
import goplausibleLogo from '../assets/images/goPlausible-logo-type-h.png';

class Main extends React.Component {
    constructor() {
        super();
        this.state = {

        };
    }

    render() {
        return (
            <Box component={"div"} sx={{width:"100%"}}>
            <br />
            <Box component={"img"} src={logo} alt="logo" sx={{ width: "128px", filter: "invert(1)" }} />
            
            <Typography color="#7c05bb" variant="h4" component="h4" gutterBottom>Coming soon:</Typography>
            <Typography color="#5605ab" variant="h2" component="h1" sx={{margin:"5px"}}gutterBottom>InstaMonet</Typography>
            <Typography color="#3c0478" variant="h6" component="h6" gutterBottom>Instantly mint DID powered, Verifiable Credential secured, NFTs from GenAI, Images, Videos and Documents & then monetize!</Typography>
            <br />
            <Typography sx={{verticalAlign:"middle", color:"black"}}  component="p" gutterBottom>Built and Powered by: </Typography>
            <Box component={"img"} src={goplausibleLogo} alt="logo" sx={{ width: { xs: "45%", sm: "35%", md: "25%" }, filter: "invert(1)", verticalAlign:"middle" }} />
            
            </Box>
        );
    }
}

export default Main;
